// Global Omnia Application space-settings

export const APP_SETTING = {

    /**
     * Domain Setup
     *
     * Specify the core domain and protocol of the application
     */
    // domain: "localhost:8000",
    // protocol: "http",
    // socket_protocol: 'ws',
    domain: "spctr.groon.io",
    protocol: "https",
    socket_protocol: 'wss',

    /**
     * Sidebar
     *
     * Specify the sidebar items that shall be in the menu of this instance
     * Example:
     *      {
     *          title: 'Some Tool',
     *          path: '/custom-module/some-tool',
     *          icon: <FittingUntitledIcon />,
     *          items: [ ...children of the same type],
     *      },
     */
    // navigation: {},

    /**
     * Widgets
     *
     * Specify the widgets that shall be available in the dashboards of this instance
     * Example:
     *      {
     *          name: "Custom Widget",
     *          description: "This is a custom widget that does something",
     *          identifier: "UNIQUE-IDENTIFIER",
     *          module: "Custom Module",
     *          component: ReactComponentToUse
     *      },
     */
    widgets: [],

    /**
     * Services
     *
     * A list of all services (their identifiers!) that are available in this instance
     * Example:
     *     {
     *          name: "Service Name",
     *          identifier: "UNIQUE-IDENTIFIER"
     *          register_redirect: '/service/something'
     *     }
     */
    services: [
        {
            name: 'PLAN AD',
            identifier: 'PLANAD',
            register_redirect: '/plan-ad'
        },
    ]

}
