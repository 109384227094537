/* eslint-disable react/no-array-index-key */
import {mergeRoutes} from "src/omnia/routes-composer";
import React, {lazy, Suspense} from "react";
import LoadingScreen from "./omnia/components/general/loading-screen";
import {Outlet} from "react-router";
import ServiceGuard from "./omnia/components/general/auth/ServiceGuard";
import {ServiceLayout} from "./omnia/components/layouts/service";
import OnIcon from "./omnia/components/elements/icon";

// BoostAd Routes
const Overview = lazy(() => import('src/views/overview'));
const AccessAccounts = lazy(() => import('src/views/access-accounts'));
const Campaigns = lazy(() => import('src/views/campaigns'));
const Presences = lazy(() => import('src/views/presences'));
const Optimization = lazy(() => import('src/views/optimization'));
const Support = lazy(() => import('src/views/support'));

export const getRoutes = () => {
  return mergeRoutes([
      {
          path: '/plan-ad',
          element: (
              <ServiceGuard serviceIdentifier={'PLANAD'}>
                  <ServiceLayout
                      sections={[
                          {
                              title: 'layout.marketing.title',
                              items: [
                                  {
                                      title: "common.overview",
                                      icon: 'BarChartSquare02',
                                      permissions: ['marketing'],
                                      path: '/plan-ad',
                                      tooltip: 'layout.tooltips.marketing.index'
                                  },
                                  {
                                      title: "layout.marketing.platforms",
                                      icon: 'LayersTwo01',
                                      permissions: ['performance_marketing'],
                                      path: '/plan-ad/platforms',
                                      tooltip: 'layout.tooltips.marketing.platforms'
                                  },
                                  {
                                      title: "marketing.projects",
                                      icon: 'Folder',
                                      permissions: ['contents_module_management'],
                                      disabled: true,
                                      path: '/plan-ad/projects',
                                      tooltip: 'layout.tooltips.marketing.projects'
                                  },
                                  {
                                      title: "layout.marketing.competitors",
                                      icon: 'UserX01',
                                      permissions: ['contents_module_management'],
                                      disabled: true,
                                      path: '/plan-ad/competitors',
                                      tooltip: 'layout.tooltips.marketing.competitors'
                                  },
                              ]
                          },
                          {
                              title: 'marketing.online_presences.social_media',
                              items: [
                                  {
                                      title: 'layout.marketing.publishing',
                                      icon: 'PlayCircle',
                                      permissions: ["publishing"],
                                      disabled: true,
                                      path: '/plan-ad/publishing',
                                      tooltip: 'layout.tooltips.marketing.publishing'
                                  },
                                  {
                                      title: 'layout.marketing.online_presences',
                                      icon: 'Signal02',
                                      permissions: ['publishing'],
                                      disabled: true,
                                      path: '/plan-ad/channels',
                                      tooltip: 'layout.tooltips.marketing.online_presences'
                                  },
                              ]
                          },
                          {
                              title: 'layout.marketing.performance_marketing',
                              items: [
                                  {
                                      title: "layout.marketing.ad_accounts",
                                      icon: 'LogIn04',
                                      permissions: ['performance_marketing'],
                                      path: '/plan-ad/ad-accounts',
                                      tooltip: 'layout.tooltips.marketing.ad_accounts'
                                  },
                                  {
                                      title: "layout.marketing.campaigns",
                                      superCustomAction: 'campaigns-list'
                                  },
                              ]
                          },
                      ]}
                      menuItems={[
                          {
                              title: "marketing.platforms.access_accounts",
                              path: '/plan-ad/accounts',
                              icon: <OnIcon size="small" iconName="Key02" />,
                          },
                      ]}
                      actionButton={{
                          title: 'Wir sind für Dich da',
                          subheader: 'Bei allen Fragen und Anliegen zum Online Marketing',
                          buttonLabel: 'Support Anfordern',
                          buttonPath: '/plan-ad/support',
                          icon: 'LifeBuoy02'
                      }}
                  >
                      <Suspense fallback={<LoadingScreen />}>
                          <Outlet />
                      </Suspense>
                  </ServiceLayout>
              </ServiceGuard>
          ),
          children: [
              {
                  path: '/plan-ad',
                  element: <Overview />
              },
              {
                  path: '/plan-ad/presences',
                  element: <Presences />
              },
              {
                  path: '/plan-ad/optimization',
                  element: <Optimization />
              },
              {
                  path: '/plan-ad/campaigns',
                  element: <Campaigns />
              },
              {
                  path: '/plan-ad/accounts',
                  element: <AccessAccounts />
              },
              {
                  path: '/plan-ad/support',
                  element: <Support />
              },
          ]
      }
  ])
}